import React, { useState } from 'react';
import { Card, Button, Collapse, ListGroup } from 'react-bootstrap';

const JsonCard = ({ data }) => {
    const [open, setOpen] = useState(false);

    return (
        <Card style={{ margin: '10px' }}>
            <Card.Body>
                <Card.Title>ID: {data.id}</Card.Title>
                <Card.Text>
                    <strong>URL:</strong> <a href={data.url} target="_blank" rel="noopener noreferrer">{data.url}</a><br />
                    <strong>Status:</strong> {data.status}<br />
                    <strong>Livemode:</strong> {data.livemode ? 'True' : 'False'}<br />
                    <strong>Created:</strong> {new Date(data.created).toLocaleString()}<br />
                    <strong>Deleted:</strong> {data.deleted ? 'True' : 'False'}
                </Card.Text>
                <Button
                    onClick={() => setOpen(!open)}
                    aria-controls="enabled-events-collapse"
                    aria-expanded={open}
                >
                    {open ? 'Hide Enabled Events' : 'Show Enabled Events'}
                </Button>
                <Collapse in={open}>
                    <div id="enabled-events-collapse">
                        <ListGroup style={{ marginTop: '10px' }}>
                            {data.enabled_events.map((event, index) => (
                                <ListGroup.Item key={index}>{event}</ListGroup.Item>
                            ))}
                        </ListGroup>
                    </div>
                </Collapse>
            </Card.Body>
        </Card>
    );
};

export default JsonCard;
