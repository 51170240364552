import React, { useState, useEffect } from "react";
import JsonCard from './JsonCard';

import axios from "axios";
import logoImage from "../../assets/images/isha-logo-black.png";
import {Col, Row, Container, Image, Button} from "react-bootstrap";
import footerImage from "../../assets/footer-image.jpg";


export default function Loader(props) {
    const [id, setId] = useState('');
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);

    const fetchDetails = async (id) => {
        try {
            const response = await axios.get(`/api/stripe/webhook-endpoint/${id}`);
            const result = response.data;
            console.log(result)
            setResult(result);
            setError(null);
        } catch (error) {
            setError(error.message);
            setResult(null);
        }
    };

    // Effect to run when `data` is updated
    useEffect(() => {
        if (result) {
            console.log('Updated data:', result);
        }
    }, [result]); // This effect runs every time `data` changes

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchDetails(id).then(r => { console.log(r)} );
    };


    return (
        <>
            <div className="bg-ishaBeige text-black py-10">
                <img alt="Isha-Logo" src={logoImage} className="Isha-Logo---Black" />
            </div>
            <Container>
                <Col
                    lg={{span: 8, offset: 2}}
                    md={{span: 10, offset: 1}}
                    xs={{span: 12}}
                    style={{backgroundColor: '#e8e8e8'}}
                    className={'flex flex-col items-center mt-20 mb-20 p-20 '}>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Enter ID:
                            <input
                                type="text"
                                value={id}
                                onChange={(e) => setId(e.target.value)}
                            />
                        </label>
                        <br/>
                        <Button className={'primary'} type="submit">Submit</Button>
                    </form>
                </Col>

            </Container>
            <Container>
            {result ? (
                <Row>
                    {result.map((data, index) => (
                        <Col key={index} md={6} lg={4}>
                            <JsonCard data={data} />
                        </Col>
                    ))}
                </Row>
            ) : (
                <p>No data available</p>
            )}
        </Container>
            <div className="w-full">
                <Image fluid style={{width: '100vw'}} src={footerImage}/>
            </div>
            <div className="bg-black opacity-75 text-white w-full py-4"/>
        </>
    );
}
