import React, {useEffect} from "react";
import {MoonLoader} from "react-spinners";

import "./loader.css";
import axios from "axios";
import logoImage from "../../assets/images/isha-logo-black.png";
import {Col, Container, Image} from "react-bootstrap";
import footerImage from "../../assets/footer-image.jpg";


export default function Loader(props) {
    const region = window.decodeURIComponent(props.match.params.region);
    const txnId = window.decodeURIComponent(props.match.params.txnId);
    useEffect(() => {
        let counter = 0;
        let intervalID = setInterval(async () => {
            if(counter <=35){
                const {data} = await axios.get(`/api/transaction/status/${txnId}`);
                console.log(data)
                if (data === "COMPLETED" || counter >= 35) {
                    clearInterval(intervalID);
                    document.getElementById('txnId').value = txnId;
                    document.payTypeForm.method = 'post';
                    document.payTypeForm.submit();
                }
                counter++
                console.log('counter=',counter)
            }
        }, 1000);
    }, []);

    return (
        <>
            <div className="bg-ishaBeige text-black py-10">
                <img alt="Isha-Logo" src={logoImage} className="Isha-Logo---Black" />
            </div>
            <Container>
                <Col
                    lg={{ span: 8, offset: 2 }}
                    md={{ span: 10, offset: 1 }}
                    xs={{ span: 12 }}
                    style={{ backgroundColor: '#e8e8e8' }}
                    className={'flex flex-col items-center mt-20 mb-20 p-20 '}>
                    <p className="font-medium text-3xl mb-10">Please Wait</p>

                    <MoonLoader/>
                    <form name="payTypeForm" action="/callback/status" method="post" className={'w-full'}>
                        <input type="hidden" name="txnId" id="txnId" value=""/>
                    </form>
                    <p className="text-kala text-base text-center book-font mb-4" />
                    <p>The transaction is being processed</p>
                </Col>
            </Container>
            <div className="w-full">
                <Image fluid style={{ width: '100vw' }} src={footerImage} />
            </div>
            <div className="bg-black opacity-75 text-white w-full py-4" />
        </>
    );
}
