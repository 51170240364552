import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Loader from "../pages/Loader/"
import Stripe from "../pages/Stripe";
import RazorPay from "../pages/RazorPay";
import CcAvenue from "../pages/CcAvenue";
import PaymentError from "../pages/PaymentError";
import PaymentAborted from "../pages/PaymentAborted";
import PaymentMethod from "../pages/PaymentMethod";
import ESewa from "../pages/ESewa";
import PayUPage from "../pages/PayU";
import FonePay from "../pages/FonePay";
import StripeAPI from "../pages/StripeAPI";

const Router = () => (
  <Switch>
    <Route exact path="/stripe-checkout/:providerId/:sessionId" component={Stripe}/>
    <Route exact path="/razorpay/:uniqueId/:orderId/:txnId" component={RazorPay}/>
    <Route exact path="/ccavenue/:encData/:code" component={CcAvenue}/>
    <Route exact path="/aborted/:uniqueId/:txnId" component={PaymentAborted}/>
    <Route exact path="/error/:message" component={PaymentError}/>
    <Route exact path="/error" component={PaymentError}/>
    <Route exact path="/wait/:txnId" component={Loader}/>
    <Route exact path="/sge/:uniqueId/:prodDesc" component={PaymentMethod}/>
    <Route exact path="/esewa/:txnId/:orderAmount" component={ESewa}/>
    <Route exact path="/payu/:txnId/:hash/:source" component={PayUPage}/>
    <Route exact path="/fonepay" component={FonePay}/>
    <Route exact path="/stripe-endpoints" component={StripeAPI}/>
  </Switch>
);

export default Router;
